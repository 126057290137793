
hr {
    border: 0;
    clear:both;
    display:block;
    width: 100%;                
    background-color:#d0d0d0;
    height: 1px;
    /* margin: 5px 0px 0px 0px; */
  }
   
  
  #Header {
    /* margin: 0px auto 10px auto; */
    width: 100%;
    height: 50px;
    background: #F5F5F5;
    overflow-x: auto;
  }
  
  .titlebar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-grow: 0;
    align-items: center;
  }
  
  .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;
    color: black;
  }
  
  #nawi_logo {
    display: flex;
    align-items: center;
    height: 50px;
    /* background: #f2f7f8; */
    padding: 0 10px 0 10px;
  }
  
  #nawi_logo img {
    height: 40px;
  }
  
  #titlebar-name {
    display: flex;
    margin-top: 5px;
    padding: 0 10px 0 10px;
    color: black;
    font-weight: bold;
    font-size: 25px;
  }